const $carousel = document.getElementById("carousel-horizontal");
const $track = document.getElementById("track-horizontal");
const $slides = document.querySelectorAll(".slide-horizontal");
const $lastSlide = $slides.item($slides.length - 1);
const $firstSlide = $slides.item(0);

const GAP = 20;
const INTERVAL = 2000;
const DURATION = "duration-1000";

let slidesCount,
  activeSlideIndex,
  translateX,
  spaceEnd,
  prevMoveTimestamp,
  carouselWidth,
  trackWidth,
  moveFrame,
  paintedSlide;

let movedToStart = [],
  movedToEnd = [];

function init() {
  if (moveFrame) {
    cancelAnimationFrame(moveFrame);
  }

  slidesCount = $slides.length;
  activeSlideIndex = 0;
  translateX = 0;
  spaceEnd = 0;
  prevMoveTimestamp = null;
  carouselWidth = $carousel.offsetWidth;
  trackWidth = $track.offsetWidth;

  const activeSlideWidth = $firstSlide.offsetWidth;
  translateX = carouselWidth / 2 - (activeSlideWidth / 2 + GAP / 2);

  toggleTrackDuration(true);
  $track.style.cssText = `transform: translate3d(${translateX}px, 0px, 0px)`;

  fillSlides();
  paintSlide($firstSlide);

  moveFrame = requestAnimationFrame(moveTick);
}

function moveTick(timestamp) {
  if (!prevMoveTimestamp) {
    prevMoveTimestamp = timestamp;
  }

  const diff = timestamp - prevMoveTimestamp;

  if (diff > INTERVAL) {
    moveTrack();
    prevMoveTimestamp = timestamp;
  }

  moveFrame = requestAnimationFrame(moveTick);
}

function moveTrack() {
  activeSlideIndex = getNextIndex();

  if (translateX < 0 && activeSlideIndex === 0) {
    resetTrack();
  } else {
    const activeSlide = $slides.item(activeSlideIndex);
    const activeSlideWidth = activeSlide.offsetWidth;
    const prevSlide = $slides.item(activeSlideIndex - 1);
    const prevSlideWidth = prevSlide?.offsetWidth;

    translateX -= GAP + activeSlideWidth / 2 + prevSlideWidth / 2;

    fillSlides();
    paintSlide(activeSlide);

    $track.style.cssText = `transform: translate3d(${translateX}px, 0px, 0px)`;
  }
}

function fillSlides() {
  const spaceStart = translateX;
  spaceEnd = $carousel.offsetWidth - ($track.offsetWidth + translateX);

  if (spaceStart > 0) {
    let slidesWidth = 0;
    Array.from($slides)
      .reverse()
      .forEach(function (slide) {
        if (slidesWidth < spaceStart) {
          slidesWidth += slide.offsetWidth + GAP;
          slide.style.cssText = `transform: translate3d(-${$track.offsetWidth}px, 0px, 0px);`;
          movedToStart.push(slide);
        }
      });
  } else if (movedToStart.length > 0) {
    let done = false;
    function tick(timestamp) {
      if (timestamp - prevMoveTimestamp > INTERVAL) {
        movedToStart.forEach(function (slide) {
          slide.style.cssText = null;
        });
        movedToStart = [];
        done = true;
      }
      if (!done) {
        requestAnimationFrame(tick);
      }
    }
    requestAnimationFrame(tick);
  }

  if (spaceEnd > 0) {
    let slidesWidth = 0;
    $slides.forEach(function (slide) {
      if (slidesWidth < spaceEnd) {
        slidesWidth += slide.offsetWidth + GAP;
        slide.style.cssText = `transform: translate3d(${$track.offsetWidth}px, 0px, 0px);`;
        if (!movedToEnd.includes(slide)) {
          movedToEnd.push(slide);
        }
      }
    });
  } else if (movedToEnd.length > 0) {
    movedToEnd.forEach(function (slide) {
      slide.style.cssText = null;
    });
    movedToEnd = [];
  }
}

function resetTrack() {
  carouselWidth = $carousel.offsetWidth;
  toggleTrackDuration(false);
  console.log("asdf");
  const firstSlideWidth = $firstSlide.offsetWidth;
  const lastSlideWidth = $lastSlide.offsetWidth;

  translateX = carouselWidth / 2 - -GAP / 2 + lastSlideWidth / 2;

  console.log(carouselWidth, firstSlideWidth, lastSlideWidth);

  $track.style.cssText = `transform: translate3d(${translateX}px, 0px, 0px)`;
  fillSlides();

  init();
}

function getNextIndex() {
  return activeSlideIndex + 1 > slidesCount - 1 ? 0 : activeSlideIndex + 1;
}

function toggleTrackDuration(force) {
  $track.classList.toggle(DURATION, force);
}

function paintSlide(activeSlide) {
  bleachSlide();
  activeSlide.classList.add(
    "from-secondary-green",
    "to-main-black",
    "bg-gradient-to-r",
    "bg-clip-text",
  );
  paintedSlide = activeSlide;
}

function bleachSlide() {
  if (!paintedSlide) return;
  paintedSlide.classList.remove(
    "from-secondary-green",
    "to-main-black",
    "bg-gradient-to-r",
    "bg-clip-text",
  );
  paintedSlide = null;
}

document.fonts.ready.then(function () {
  init();

  window.addEventListener("resize", init);
});
